import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import AniLink from "gatsby-plugin-transition-link/AniLink";


import ButtonSolid from "../Button/ButtonSolid";

const CTA = ({
  headingSecond,
  heading,
  headingLevel,
  ctaLink,
  rotatingWords,
  contactOwner,
  className,
}) => {
  const HeadingTag = headingLevel || "h4";

  const data = useStaticQuery(graphql`
    {
      horseHead: file(relativePath: { eq: "global/logo-head-gold.svg" }) {
        publicURL
      }
      rotatingWords: file(
        relativePath: { eq: "global/rotating-words-gold.svg" }
      ) {
        publicURL
      }
      rotatingWordsTaxes: file(
        relativePath: { eq: "global/rotating-words-gold.svg" }
      ) {
        publicURL
      }
      rotatingWordsBookkeeping: file(
        relativePath: { eq: "global/rotating-words-books-orange.svg" }
      ) {
        publicURL
      }
      rotatingWordsCFO: file(
        relativePath: { eq: "global/rotating-words-cfo-orange.svg" }
      ) {
        publicURL
      }
    }
  `);

  let rotatingWordsSource;

  rotatingWords === "Taxes"
    ? (rotatingWordsSource = data.rotatingWordsTaxes.publicURL)
    : rotatingWords === "Bookkeeping"
    ? (rotatingWordsSource = data.rotatingWordsBookkeeping.publicURL)
    : rotatingWords === "Fraction CFO"
    ? (rotatingWordsSource = data.rotatingWordsCFO.publicURL)
    : (rotatingWordsSource = data.rotatingWords.publicURL);

  const setLocalStorageCity = () => {
    if (contactOwner) {
      localStorage.setItem("contact-owner", contactOwner);
    }
  };

  return (
    <div className="container pt-[100px] text-center md:text-left mx-auto">
      <section
        className={`relative mt-5 mb-20 ml-2 mr-4 rounded-3xl bg-[#1E2A33]  px-8 pt-28 pb-26 md:mt-3 md:mb-32  md:py-24 ${
          className || ""
        }`}
      >
        <div className="absolute left-2 top-2 z-[-10] h-full w-full rounded-3xl goldenGrad md:left-3 md:top-3"></div>
        <div className="absolute -left-3.5 -top-6 flex h-[120px] w-[120px] items-center justify-center md:-top-16 md:-left-12 md:h-[200px] md:w-[200px]">
          <img
            src={rotatingWordsSource}
            alt="Rotating words"
            width="200"
            className="rotating absolute inset-0"
          />
          <img
            src={data.horseHead.publicURL}
            alt="Dark Horse logo"
            className="w-[45px] md:w-[75px]"
          />
        </div>
        <header className="grid grid-cols-12 max-w-[817px] mx-auto">
          <div className="w-full col-span-12 md:col-span-9 px-4 md:px-0 md:pr-12">
            <HeadingTag className="text-white heading-three mb-1">
              {heading || [
                "It Really is a No-Brainer "
              ]}
            </HeadingTag>
            <div>
              <p className="text-white">
                If a{" "}
                <AniLink className="text-[#E1C05F] no-underline hover:underline hover:text-[#E1C05F]" fade target="_blank" to="https://advisors.vanguard.com/insights/article/IWE_ResPuttingAValueOnValue">
                  good financial advisor increases your returns by an additional 3% per year,  
                </AniLink> imagine what the Dark Horse team can do for you.
              </p>
            </div>
            <HeadingTag className="text-white heading-three mb-0">
              {headingSecond || [
                "Reach out for a free consultation."
              ]}
            </HeadingTag>
          </div>
          <div className="w-full col-span-12 md:col-span-3 align-bottom justify-bottom place-items-end mt-[30px] md:mt-[52%]">
            
            <ButtonSolid
              href={`${ctaLink ? ctaLink : "/schedule-consult/"}`}
              text="Get Started"
              className="bg-[#E1C05F] hover:bg-[#f3e6bf] hover:text-black"
            />
          </div>



        </header>
      </section>
    </div>
  );
};

export default CTA;
