import React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { mapEdgesToNodes, toPlainText } from '../lib/helpers'

import Layout from '../components/Layout'
import SearchEngineOptimization from '../components/SEO'
import timeline from '../images/Process/timeline_object.svg'
import bugs from '../images/philosophy/mosquitoes.svg'
import ButtonSolid from '../components/Button/ButtonSolid'
import CallToAction from "../components/Repeating/CTA2";

export const data = graphql`
  {
    processHero: file(relativePath: { eq: "Process/hero-process.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, width: 530)
      }
    }
    timelineObject: file(relativePath: { eq: "Process/process-dots.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    homepageCoinStack: file(relativePath: { eq: "homepage/3-coinstack.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, width: 530)
      }
    }
    homepageVenDiagram: file(relativePath: { eq: "homepage/2-intro.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, width: 530)
      }
    }
    grayCrosses: file(relativePath: { eq: "locations/gray-crosses.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    homepageHeroCoins: file(
      relativePath: { eq: "homepage/hero-illustration-grouped.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 530)
      }
    }
    homepageHeroDesktop: file(
      relativePath: { eq: "locations/gray-crosses.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    openGraphImage: file(
      relativePath: { eq: "open-graph/facebook/Global.jpg" }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: { eq: "open-graph/twitter/Global.jpg" }
    ) {
      publicURL
    }
    subServices: allSanitySubService(
      filter: { slug: { current: { ne: null } } }
      sort: { fields: [order], order: ASC }
    ) {
      edges {
        node {
          title
          slug {
            current
          }
        }
      }
    }
    sectionDivider: file(
      relativePath: { eq: "philosophy/process-dots.svg" }
    ) {
      publicURL
    }
    horseHeadBlue: file(relativePath: { eq: "global/logo-head-blue.svg" }) {
      publicURL
    }
    philosophyHeroUnderlay: file(
      relativePath: { eq: "philosophy/2-line background.svg" }
    ) {
      publicURL
    }
    hero: file(relativePath: { eq: "foreign Tax/hero.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 624)
      }
    }
    taxesCanBeStressful: file(
      relativePath: { eq: "Personal Taxes/taxes-can-be-stressful.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 560)
      }
    }
    team: file(relativePath: { eq: "Personal Taxes/team.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 1200)
      }
    }
    compOut: file(relativePath: { eq: "foreign Tax/outbound.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 560)
      }
    }
    compIn: file(relativePath: { eq: "foreign Tax/inbound.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 560)
      }
    }
    taxes: file(relativePath: { eq: "Personal Taxes/taxes.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 560)
      }
    }
    orangeSquare: file(
      relativePath: { eq: "global/decorative-orange-square.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 160)
      }
    }
    orangeCrosses: file(
      relativePath: { eq: "global/decorative-orange-crosses.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 192)
      }
    }
    homepageDictionary: file(
      relativePath: { eq: "homepage/4-dictionary.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 493)
      }
    }
  }
`

const Page = ({ data }) => {
  // const faqs = [
  //     {
  //         question:
  //             "U.S. citizens and residents living abroad must still file their U.S. federal taxes, and most expats are required to file additional tax forms before relocating? ",
  //         answer: (
  //             <>
  //                 <p className="mb-0">
  //                     Yeah, I’m going to need help with that. Not to worry—the experts at
  //                     Dark Horse will lead the way.
  //                 </p>
  //             </>
  //         ),
  //     },
  //     {
  //         question:
  //             "Many U.S. taxpayers living abroad actually end up owing no taxes, or a bare minimum?",
  //         answer: (
  //             <>
  //                 <p className="mb-0">
  //                     How can that be? Oh, it be… because we help you take advantage of
  //                     all the tax benefits and exclusions that are available for expats.
  //                 </p>
  //             </>
  //         ),
  //     },
  //     {
  //         question:
  //             "If an international tax form is not filed or there is a significant error, the penalty can be $10,000 per return (up to $60,000)?",
  //         answer: (
  //             <>
  //                 <p className="mb-0">
  //                     h no, what if that happens to me? Not to worry—Dark Horse to the
  //                     rescue. We can help with delinquent late filing and abatement, or
  //                     outline a streamlined process for you to get caught up with the
  //                     filings.
  //                 </p>
  //             </>
  //         ),
  //     },
  //     {
  //         question: "The IRS can track your foreign financial accounts? ",
  //         answer: (
  //             <>
  //                 <p className="mb-0">
  //                     They can? Yes, they certainly can. You see, the federal law “FATCA”
  //                     (Foreign Account Tax Compliance Act) requires Americans, U.S.
  //                     residents, and companies to disclose their oversea financial
  //                     holdings, including bank and brokerage accounts, if the threshold is
  //                     met. There is no way to avoid it—FATCA enforces foreign financial
  //                     institutions to report the data of U.S. account owners yearly. We’ll
  //                     help identify and prepare the required filings so you remain
  //                     compliant.
  //                 </p>
  //             </>
  //         ),
  //     },
  //     {
  //         question:
  //             "If you live abroad, you may not have to pay Social Security and Medicare taxes to the U.S.? ",
  //         answer: (
  //             <>
  //                 <p className="mb-0">
  //                     Tell me more. If the country you reside in entered into Totalization
  //                     Agreements with the U.S. (25 countries have), the amount you
  //                     contribute to local social security will give you a credit with the
  //                     U.S. Social Security Administration. If you fit the bill, we’ll let
  //                     you know.
  //                 </p>
  //             </>
  //         ),
  //     },
  //     {
  //         question:
  //             "American expats have different tax filing deadlines and extensions?",
  //         answer: (
  //             <>
  //                 <p className="mb-0">
  //                     What are those dates? Expats get a special automatic two-month
  //                     extension to June 15. If more time is needed, they can write to the
  //                     IRS to request a further extended deadline (to as far out as
  //                     December 15). Payment of taxes due is still April 15. If you’re bad
  //                     at dates, you can relax. Dark Horse can manage your tax filing and
  //                     make sure you stay on track.
  //                 </p>
  //             </>
  //         ),
  //     },
  //     // {
  //     //   question: "Can I drop my files off at your office?",
  //     //   answer: (
  //     //     <>
  //     //       <p className="mb-0">
  //     //         The short answer is no. The long answer is that we’re able to keep
  //     //         our prices extremely competitive with a much higher value
  //     //         proposition by working in a cloud-based environment. We’ll ask that
  //     //         you upload your documents to our secure client portal, as this
  //     //         creates indispensable efficiencies in preparing your tax returns, as
  //     //         well as the fact that you will remain the custodian of those
  //     //         documents for peace of mind. We can help you figure out how to
  //     //         digitize those documents, even if you don’t have a scanner. Last,
  //     //         all of your tax documents and tax returns will be saved in the
  //     //         secure portal, allowing for easy access in the future.
  //     //       </p>
  //     //     </>
  //     //   ),
  //     // },
  //     // {
  //     //   question: "Do I need to come into your office to sign documents?",
  //     //   answer: (
  //     //     <>
  //     //       <p className="mb-0">
  //     //         Not at all! Our entire process can be done in the comfort of your
  //     //         home. Any document that we will need you to sign can be
  //     //         electronically signed. All documents (tax documents, tax returns,
  //     //         engagement letters, IRS correspondence, etc.) are saved to your
  //     //         secure portal, accessible at any time.
  //     //       </p>
  //     //     </>
  //     //   ),
  //     // },
  //     // {
  //     //   question:
  //     //     "I tend to forget what exactly happened on previous years’ tax returns…do you provide a tax return review meeting to go over the current tax return and how it compares to previous years?",
  //     //   answer: (
  //     //     <>
  //     //       <p className="mb-0">
  //     //         Yes, we do! We have a couple of options based on your preference. We
  //     //         can provide you with a review via email, a video recording of your
  //     //         CPA walking through your return, or a 1-to-1 meeting.
  //     //       </p>
  //     //     </>
  //     //   ),
  //     // },
  //     // {
  //     //   question:
  //     //     "I’m not confident that last year’s tax return was done correctly or that I left deductions on the table. Will you review those tax returns to address these doubts?",
  //     //   answer: (
  //     //     <>
  //     //       <p className="mb-0">
  //     //         Of course. That’s part of our onboarding process with new clients.
  //     //         We’ll let you know if there are any red flags or missed
  //     //         opportunities on those returns. If necessary, we can amend previous
  //     //         tax returns to make sure those issue or opportunities are taken care
  //     //         of.
  //     //       </p>
  //     //     </>
  //     //   ),
  //     // },
  //     // {
  //     //   question: "How much do you charge?",
  //     //   answer: (
  //     //     <>
  //     //       <p className="mb-0">
  //     //         Prices range based on the scope of the engagement. We do offer a
  //     //         unique price quote tool on our website found{" "}
  //     //         <AniLink fade to="/personal-pricing/">
  //     //           here
  //     //         </AniLink>{" "}
  //     //         to give you an idea. Generally, it’s based on your income/deduction
  //     //         sources, how many states you need to file in, and if there are any
  //     //         unique situations with your return. If you are curious, you can
  //     //         always request a quote from us{" "}
  //     //         <AniLink fade to="/get-a-quote/">
  //     //           here
  //     //         </AniLink>
  //     //         .
  //     //       </p>
  //     //     </>
  //     //   ),
  //     // },
  //     // {
  //     //   question:
  //     //     "Do you prepare returns in-person like the brick and mortar tax prep firms?",
  //     //   answer: (
  //     //     <>
  //     //       <p className="mb-0">
  //     //         No, we do not. The reason is that the tax returns we prepare for
  //     //         clients have too much at stake to do all in one sitting. We prepare
  //     //         your returns thoughtfully, review them for quality and accuracy and
  //     //         then evaluate them strategically to make sure you're getting the
  //     //         best result possible. It's impractical (and impossible) to try to do
  //     //         this while you're sitting across from us. Also, you've got better
  //     //         things to do than sit around while we prepare the returns anyway.
  //     //       </p>
  //     //     </>
  //     //   ),
  //     // },
  // ];

  const { props, errors } = data
  const page = props && props.page

  const truncate = str => {
    return str.length > 300 ? str.substring(0, 300) : str
  }

  const subServiceNodes =
    data && data.subServices && mapEdgesToNodes(data.subServices)

  return (
    <Layout className='bg-secondary-100 ' serviceType='cpa'>
      <SearchEngineOptimization
        title='Our Process | Dark Horse Private Wealth'
        description='Discover what building wealth looks like when you partner with Dark Horse. From organizing and optimizing to implementing and monitoring, we are in your corner.'
        openGraphImage={data.openGraphImage.publicURL}
        twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />

      

      <section className='relative bg-primary-600 pt-20 pb-20 md:pt-32 md:pb-36'>
        <div className='container'>
        
          <div className='mb-6 grid items-center gap-y-14 md:grid-cols-2 md:gap-x-10 lg:gap-x-28'>
            <div className="order-last md:order-first">
              <div className='text-white'>
                <h2 className='text-white'>
                  Dark Horse Private Wealth: Our Process
                </h2>
                <p>
                  What does building your wealth look like when you partner with
                  Dark Horse? After getting to know you, we start tightening the
                  reins so you can begin seeing progress right away. And then we
                  keep you on track year-over-year. 
                </p>
                <ButtonSolid
                  href='/schedule-consult/'
                  text='Get Started'
                  altStyle={3}
                  className='w-full md:w-auto'
                />
              </div>
            </div>
            <div className="order-first md:order-last">
              <div className='relative ml-8 mr-4 lg:mx-0'>
                <div className='relative z-10'>
                  <GatsbyImage
                    image={data.processHero.childImageSharp.gatsbyImageData}
                    className='z-0 w-full rounded-4xl lg:min-h-[450px]'
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* 
      <section className='bg-secondary-100  pb-20 '>
        <div className="z-[1] h-full w-full overflow-hidden bg-[url('../images/Process/timeline_object.svg')] bg-[length:100%]  bg-no-repeat" />
      </section> */}

      {/* page content */}<section className="bg-secondary-100">
      <div className='container mx-auto px-4 py-14 relative'>
        <div className='absolute left-[50%] top-[12%] invisible md:visible'>
         <GatsbyImage
            objectFit=''
            imgClassName=''
            className='w-[22px] '
            image={data.timelineObject.childImageSharp.gatsbyImageData}
          /> 
        </div>

        <div className="grid md:grid-cols-2 md:gap-10 justify-items-center">
            <div className="self-center content-center order-[1]">
                <h2 class='text-3xl text-left md:text-[60px] md:leading-[70px] bold text-[#CFD7DA] mb-[10px] '>
                    Step 1
                </h2>
                <p className='text-left text-[#E1C05F] md:text-[24px] leading-[34px] font-bold'>
                    Discovery
                </p>
            </div>
            <div className="self-center content-center m-4 md:ml-[80px] md:my-[20px] order-[2]">
              <h2 className='mb-1.5 text-left text-xl md:text-[38px] leading-[42px]'>
                Getting to Know You
              </h2>
              <p className='text-left italic  text-[#A1A5A8]'>
                Who – You and Dark Horse Private Wealth Team
              </p>
              <p className='text-left'>
                <p>Upon introduction from your Dark Horse CPA, you’ll receive a
                web-based fact finder and encrypted link to upload your
                statements.</p> <p>Once we gather your financial information, we’ll
                schedule and conduct a virtual discovery meeting to learn about
                your values and prioritize your goals.</p> <p> At the end of the
                meeting, we’ll determine which service model is most appropriate
                for your objectives.</p>  
                <p>Next, you’ll receive an engagement proposal
                outlining the scope of work and our transparent fee agreement.
                You can review/sign electronically and we’re off to the lab to
                get started on your custom plan.</p>
              </p>
            </div>
          

          <div className="self-center content-center m-4 md:m-[30px] order-[4] md:order-[3]">
          <h2 className='mb-1.5 text-left text-xl md:text-[38px] leading-[42px]'>
                Getting Your ‘One of a Kind’ Plan Dialed
              </h2>
              <p className='text-left italic  text-[#A1A5A8]'>
              Who – Dark Horse Private Wealth Team
              </p>
              <p className='text-left'>
<p>This is where we combine the art and science of getting your financial life highly organized and optimized.</p>
<p>You’ve met with your advisor to establish what you want your future life to look like. Now our team will backcast a set of actions that are necessary in the present to accomplish your future goals.</p>
<p>We’ll utilize multiple cutting-edge fintech tools to analyze your current path, test for inefficiencies and identify optimal solutions/strategies to help get you where you want to be.</p>

<b>The resulting plan will be dynamic, clear and concise - designed to help you make informed decisions.</b>
              </p>
            
           </div>

            <div className="self-center content-center order-[3] md:order-[4]">
                
            <h2 class=' mb-[10px] text-3xl text-left md:text-[60px] md:leading-[70px] bold text-[#CFD7DA] '>
                Step 2
              </h2>
              <p className='text-left text-[#E1C05F] md:text-[24px] leading-[34px] font-bold'>
                Strategy
              </p>
            </div>

            <div className="self-center content-center order-[5]">
            <h2 class='text-3xl text-left md:text-[60px] md:leading-[70px] bold text-[#CFD7DA] mb-[10px] '>
                Step 3
              </h2>
              <p className='text-left text-[#E1C05F] md:text-[24px] leading-[34px] font-bold'>
                Collaboration
              </p>
            
            </div>
            <div className="self-center content-center m-4 md:ml-[80px] md:my-[20px] order-[6]">
            <h2 className='mb-1.5 text-left text-xl md:text-[38px] leading-[42px]'>
                The Expertise & Advice You Need, All In-House
              </h2>
              <p className='text-left italic  text-[#A1A5A8]'>
                Who – Dark Horse CPA & Private Wealth Team
              </p>
              
                <p className='mb-4'>
                  By utilizing all the services Dark Horse has to offer, you’ll
                  benefit from objective advice and a personal connection to a
                  multidisciplinary team devoted to your success.
                </p>
                <p className='mb-4'>
                  The Dark Horse Private Wealth Team has completed your version
                  1.0 plan. Now it’s time to send it to quality control under
                  the discerning eye of your Dark Horse CPA.{' '}
                </p>
                <p className='mb-4'>
                  True COLLABORATION between your professionals integrates all
                  aspects of your financial life into a comprehensive,
                  actionable plan. Both teams join forces to fully understand
                  how various decision points will impact your tax liability and
                  uncover any additional tax savings opportunities.
                </p>
                <p>
                  Your version 2.0 plan is now ready to be presented and we’ll
                  reach out to schedule a virtual meeting to review it.
                </p>
            
            </div>

            <div className="self-center content-center order-[8] md:order-[7]">
            <h2 className='mb-1.5 text-left text-xl md:text-[38px] leading-[42px]'>
                Recommendation, Implementation & Monitoring
              </h2>
              <p className='text-left italic  text-[#A1A5A8]'>
                Who – You and Dark Horse Private Wealth Team
              </p>
              <p className='text-left'>
                <p>This is an interactive session where we distill the hundreds of
                pages of analytics down to a dozen or so action items and walk
                you through these three steps:</p>
                <ol>
                  <li className='mt-4 mx-6'>
                  <p className='mb-[4px]' >  <b>1)</b> We’ll present you with recommendations across all areas
                    of your financial life, from investments to insurance to
                    legacy planning… the list goes on.</p>
                  </li>
                  <li className='mt-4 mx-6'>
                   <p className='mb-[4px]' > <b>2)</b> We’ll review a planning calendar that outlines all
                    activities to be completed over the next twelve months.</p>
                  </li>
                  <li className='mt-4 mx-6'>
                   <p className='mb-[14px]'> <b>3)</b> You’ll be given login information to your own personal
                    Dark Horse website and app, where you can track your plan
                    progress and investments daily (if you so choose).</p>
                  </li>
                </ol>
              <p>   We approach wealth management as an ongoing, dynamic process and
                will assess your situation on an ongoing basis. At Dark Horse,
                we promise to serve your family for decades to come and will
                continually support your financial success through scheduled and
                on-demand check-ins so you can focus on what really matters in
                your life. And every year, this step of the process matures as
                we grow together.</p>
              </p>
            
            </div>
            <div className="self-center content-center order-[7] md:order-[8]">
            <h2 class='text-3xl text-left md:text-[60px] md:leading-[70px] bold text-[#CFD7DA] mb-[10px] '>
                Step 4
              </h2>
              <p className='text-left text-[#E1C05F] md:text-[24px] leading-[34px] font-bold'>
                Execution
              </p>
            
            </div>
            </div>




        

      </div>
      {/* end of page content */}
      </section>
      <section className="bg-secondary-100 mb-0 zigZagBg">
        <div class="container py-[50px]">
          
          <CallToAction rotatingWords="Taxes" />
          </div>
      </section>
    </Layout>
  )
}

export default Page
